import { RowFlex } from '@/themes/genericStyles'
import styled from 'styled-components'

export const Container = styled.div`
    ${RowFlex}
    align-items: center;
    padding: 16px;
    gap: 10px;

    justify-content: end;

    position: sticky;
    top: 0px;
    background: #141414;
    height: 70px;
    border-bottom: 4px solid #0a0a0a;
    z-index: 99;

    width: -webkit-fill-available;
    overflow-x: auto;
    &::-webkit-scrollbar {
        width: 10px;
    }
    width: -moz-available;

    @media (max-width: 990px) {
        display: ${(props) => (props.genrePage ? 'none' : 'unset')};
        padding: 0px 16px;
    }

    @media (max-width: 500px) {
        margin-bottom: -16px;
    }
`
export const HeaderInnerContainer = styled.div`
     {
        ${RowFlex}
        align-items: center;
        padding: 0px;
        gap: 410px;

        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 1;
    }
`

export const ListItems = styled.div`
    ${RowFlex}
    padding: 0;
    margin: 0;
    justify-content: space-between;
    list-style-type: none;
    // border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    @media (min-width: 991px) {
        justify-content: flex-start;
        gap: 1rem;
    }
`
export const Item = styled.a`
    color: ${(props) => (props.active ? '#ffffff' : '#888888')};
    cursor: pointer;
    font-size: 14px;
    text-decoration: none;
    padding: 10px 12px;
    position: relative;
    flex: none;
    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${(props) =>
            props.active ? '#fad810' : 'transparent'};
    }
`

export const LoginButtonContainer = styled.div`
     {
        ${RowFlex}
        justify-content: center;
        align-items: center;
        padding: 0px;

        /* Inside auto layout */

        flex: none;
        order: 3;
        flex-grow: 0;
        @media (max-width: 990px) {
            display: none;
        }
        // cursor: pointer;

        justify-content: ${(props) =>
            props.genrePage ? 'flex-end' : 'center'};
`
export const LoginButton = styled.div`
     {
        ${RowFlex}
        height: 40px;
        padding: 8px 24px;

        justify-content: center;
        align-items: center;
        gap: 8px;

        background: #fad810;
        border-radius: 36px;
        cursor: pointer;
    }
`
export const LoginButtonText = styled.div`
     {
        font-weight: 600;
        font-size: 14px;
        line-height: 17.5px;
        letter-spacing: 0.1px;
        color: #1f1f1f;
        cursor: pointer;
    }
`

export const UserDetailContainer = styled.div`
     {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        cursor: pointer;
        // padding: 8px;
        // gap: 16px;
        @media (max-width: 990px) {
            display: none;
        }
        justify-content: ${(props) =>
            props.genrePage ? 'flex-end' : 'center'};
        width: ${(props) => (props.genrePage ? '100%' : 'unset')};
    }
`
export const UserLoggedInContainer = styled.div`
     {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 16px;

        width: 165px;
        height: 32px;

        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 0;
    }
`

export const UserImage = styled.img`
     {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-size: cover;
    }
`

export const UserName = styled.div`
     {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height, or 125% */

        color: #ffffff;
    }
`

// coin and diamonds info

export const BalanceInfoContainer = styled.div`
     {
        display: flex;
        height: 20.5px;
        justify-content: flex-end;
        align-items: center;
        gap: 16px;
        flex: 1 0 0;
        @media (max-width: 990px) {
            display: none;
        }
    }
`

export const CoinContainer = styled.div`
     {
        display: flex;
        align-items: center;
        gap: 4px;
    }
`
export const CoinBalanceContainer = styled.div`
     {
        display: flex;
        padding: 4px 8px;
        align-items: center;
        gap: 7px;
        border-radius: 100px;
        background: var(--black-600, #1f1f1f);
        cursor: pointer;
    }
`

export const Balance = styled.div`
     {
        color: #fff;
        font-family: Readex Pro;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 19.6px */
        letter-spacing: 0.014px;
    }
`

export const DiamondBalanceContainer = styled.div`
     {
        display: flex;
        padding: 4px 8px;
        justify-content: flex-end;
        align-items: center;
        gap: 7px;
        border-radius: 100px;
        background: var(--black-600, #1f1f1f);
    }
`

export const SubscriptionEntryPoint = styled.a`
    min-width: fit-content;
    height: 40px;
    padding: 8px 16px;
    border-radius: 36px;
    background: #611473;
    color: white;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    font-size: 14px;
    font-weight: 500;
    line-height: 17.5px;

    img {
        width: 24px;
        height: 24px;
    }

    @media (max-width: 990px) {
        display: none;
    }
`
export const InfoWrapper = styled.div`
    gap: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`
